<template>
	<div>
		<div style="margin-top: 20px; margin-bottom: 15px; position: relative;" class="input" v-if="search">
			<el-input :placeholder="posiName" :disabled="job" v-model="position_name" class="input-with-select">
				<div class="block" slot="prepend" v-if="job">
					<el-cascader v-model="valueOptions" :options="options" placeholder="职位类型" :show-all-levels="false"></el-cascader>
				</div>
				<template slot="append"><span class="pointer search-span" @click="searchClick">搜索</span></template>
			</el-input>
			<!-- hyz：遮罩层 -->
			<div style="position: absolute; top:0; width:100%;" class="block" slot="prepend">
				<el-cascader style="border: 1px solid red; width:100%;opacity: 0.0;" v-model="valueOptions"
					:options="options" placeholder="职位类型2" :props="{
			  expandTrigger: 'hover',
			  value: 'id',
			  label: 'title',
			  children: 'childs',
			}" @change="handleChange" :show-all-levels="false"></el-cascader>
			</div>
		</div>
		
		<!-- 热门职位 -->
		<div class="hot row-center" v-if="hot">
			<span>热门职位：</span>
			<div class="text-blue">
				<span v-for="item in hotList" :key="item.two_position_id" class="pointer"
					@click="hotClick(item.two_position_id, item.two_position)">{{ item.two_position }}</span>
			</div>
		</div>
		<div @click="childMethod" class="Detailed-screening" v-if="particular">
			<div class="title">
				<span>所在地区:</span>
				<span>企业性质:</span>
				<span>行业类别:</span>
				<span>企业规模:</span>
			</div>
			<div class="message">
				<!-- 省份 -->
				<div class="city">
					<div class="item">
						<span class="pointer" :class="cityId == '' ? 'text-blue' : ''" @click="changeCity('')">全国</span>
						<span class="pointer" v-for="item in hot_city_list" :key="item.id"
							:class="cityId == item.id ? 'text-blue' : ''"
							@click="changeCity(item.id)">{{ item.label }}</span>
					</div>
					<div class="all">
						<span @click.stop="cityShow = true" class="pointer"
							:class="cityText == 'cityShow' ? 'text-blue' : ''">其他</span>
						<div class="our" v-if="cityShow">
							<div class="city-name">
								<div v-for="item in cityList" :key="item.id">
									<div class="text-blue">{{ item.name }}</div>
									<div class="space-between city-div">
										<span v-for="itemOne in item.list" :key="itemOne.id"
											class="label-span pointer text-ellipsis"
											:class="cityId == itemOne.id ? 'text-blue' : ''"
											@mouseenter="cityId = itemOne.id"
											@click.stop="changeCity(itemOne.id, 'cityShow')">{{ itemOne.label }}</span>
										<span class="label-span" v-for="num in item.list.length % 5"
											:key="num + item.id"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 企业性质 -->
				<div class="city">
					<div class="item">
						<span class="pointer" :class="natureId == '' ? 'text-blue' : ''"
							@click="threeCategory('', 'nature')">不限</span>
						<span class="pointer" :class="natureId == item.value ? 'text-blue' : ''"
							v-for="item in natureList" :key="item.value"
							@click="threeCategory(item.value, 'nature')">{{ item.label }}</span>
					</div>
				</div>
				<!-- 行业类别 -->
				<div class="city">
					<div class="item">
						<span class="pointer" :class="industryId == '' ? 'text-blue' : ''"
							@click="threeCategory('', 'industry')">不限</span>
						<span class="pointer" :class="industryId == item.value ? 'text-blue' : ''"
							v-for="item in industryList" :key="item.value"
							@click="threeCategory(item.value, 'industry')">{{ item.label }}</span>
					</div>
				</div>
				<!-- 企业规模 -->
				<div class="city">
					<div class="item">
						<span class="pointer" :class="scaleId == '' ? 'text-blue' : ''"
							@click="threeCategory('', 'scale')">不限</span>
						<span class="pointer" :class="scaleId == item.value ? 'text-blue' : ''"
							v-for="item in scaleList" :key="item.value"
							@click="threeCategory(item.value, 'scale')">{{ item.label }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import posiApi from "../api/position";
	import toolApi from "../api/tool";
	export default {
		props: {
			search: {
				type: Boolean,
				default: true,
			},
			//  首页热门
			hot: {
				type: Boolean,
				default: true,
			},
			// 职位类型
			job: {
				type: Boolean,
				default: true,
			},
			// 省份
			jobCity: {
				type: Boolean,
				default: true,
			},
			particular: {
				type: Boolean,
				default: true,
			},
			placeholder: {
				type: String,
				default: "请选择",
			},
			posiName: {
				type: String,
				default: "搜索职类",
			},
			optionsValue: {
				type: Array,
				default: () => {
					return [];
				},
			},
		},
		data() {
			return {
				position_name: "",
				//职位类型
				value: [],
				options: [],
				// 城市
				city: [],
				cityvalue: "",
				// 热门职类
				hotList: [],
				// 企业性质
				natureList: [],
				// 行业类别
				industryList: [],
				// 行业规模
				scaleList: [],
				// 热门城市
				hot_city_list: [],
				// 过滤城市
				AF: ["A", "B", "C", "D", "E", "F"],
				GL: ["G", "H", "I", "J", "K", "L"],
				MR: ["M", "N", "O", "P", "Q", "R"],
				SZ: ["S", "T", "U", "V", "W", "X", "Y", "Z"],
				cityList: [],
				cityId: "",
				cityShow: false,
				scaleId: "",
				industryId: "",
				natureId: "",
				cityText: "",
				valueOptions: []
			};
		},
		created() {
			if (this.hot) {
				this.hotPosi();
			}
			this.addressClick();
			if (!this.jobCity) {
				this.toolCategory();
			}
			if (this.$route.query.position_name) {
				this.position_name = this.$route.query.position_name;
			}
			this.jobCategory();
		},
		mounted() {},
		watch: {
			optionsValue: {
				handler(newVal, oldVal) {
					this.valueOptions = newVal
					this.$forceUpdate()
				},
				immediate: true,
				// 这里是关键，代表递归监听 demo 的变化
				deep: true,
			},
		},
		methods: {
			childMethod(e) {
				// console.log(222, e)
				this.cityShow = false
			},
			// 获取职位类别
			jobCategory() {
				toolApi.positionCategory().then((res) => {
					if (res.code == 200) {
						this.options = res.data.data;
					}
				});
			},
			// 获取地址
			addressClick() {
				toolApi.getAddress().then((res) => {
					if (res.code == 200) {
						if (this.jobCity) {
							let city = res.data.children.map((item) => {
								return {
									value: item.id,
									label: item.label,
								};
							});
							city.unshift({
								value: 0,
								label: '全国'
							})
							this.city = city
						} else {
							this.hot_city_list = res.data.hot_proinvce;
							let cityList = res.data.children;
							let reason = cityList.filter((item) => {
								return this.AF.includes(item.first);
							});
							let reason1 = cityList.filter((item) => {
								return this.GL.includes(item.first);
							});
							let reason2 = cityList.filter((item) => {
								return this.MR.includes(item.first);
							});
							let reason3 = cityList.filter((item) => {
								return this.SZ.includes(item.first);
							});
							let reasonObj = {
								name: "A-F",
								id: "af",
								list: reason,
							};
							let reasonObj1 = {
								name: "G-L",
								id: "gl",
								list: reason1,
							};
							let reasonObj2 = {
								name: "M-R",
								id: "mr",
								list: reason2,
							};
							let reasonObj3 = {
								name: "S-Z",
								id: "sz",
								list: reason3,
							};
							this.cityList.push(reasonObj);
							this.cityList.push(reasonObj1);
							this.cityList.push(reasonObj2);
							this.cityList.push(reasonObj3);
						}
					}
				});
			},
			// 获取配置列表
			toolCategory() {
				toolApi.getUserConfig().then((res) => {
					if (res.code == 200) {
						let data = res.data;
						this.natureList = data.nature.options;
						this.industryList = data.industry.options;
						this.scaleList = data.scale.options;
					}
				});
			},
			// 热门职类
			hotPosi() {
				posiApi.hotPosi({
					size: 6
				}).then((res) => {
					if (res.code == 200) {
						this.hotList = res.data;
					}
				});
			},
			handleChange(value) {
				console.log(value)
				let path = this.$route.path;
				if (path == "/") {
					this.$router.push({
						path: "/position",
						query: {
							optionsValue: JSON.stringify(value)
						},
					});
				} else {
					this.$emit("handleChange", value[1]);
				}
			},
			// 点击搜索
			searchClick() {
				// 判断当前路由是否等于目标跳转的路由，如果是则不进行跳转，只进行搜索事件
				let path = this.$route.path;
				if (path === "/position" || path === "/personal-center") {
					this.$emit("searchClick", this.position_name);
					return;
				}
				this.$router.push({
					path: "/position",
					query: {
						position_name: this.position_name
					},
				});
			},
			hotClick(id, position) {
				let path = this.$route.path;
				if (path === "/personal-center") {
					this.$emit("hotClick", id, position);
					return;
				}
				this.$router.push({
					path: "/position",
					query: {
						id,
						position
					}
				});
			},
			// 根据省份进行筛选数据
			changeCity(id, text) {
				this.cityShow = false;
				this.cityId = id;
				if (text) {
					this.cityText = text;
				} else {
					this.cityText = "";
				}
				this.$emit("changeCity", id);
			},
			// 根据企业相关的性质进行筛选
			threeCategory(id, text) {
				if (text == "scale") {
					this.scaleId = id;
				} else if (text == "nature") {
					this.natureId = id;
				} else {
					this.industryId = id;
				}
				this.$emit("threeCategory", id, text);
			},
		},
	};
</script>
<style lang="less" scoped>
	/deep/.el-input-group__prepend {
		min-width: 102px;
	}

	.search-span {
		display: inline-block;
		width: 100%;

		height: 42px;
		text-align: center;
		line-height: 42px;
	}

	::v-deep.input .el-input__inner {
		border: none;
		border-left: 1px solid #eee;
		margin-top: 2px;
	}

	.input-with-select {
		max-width: 70.125rem;
		border: 1px solid #126bf9;
		content: "";
	}

	::v-deep .el-input-group__prepend {
		padding: 0 20px;
		background: #fff;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	::v-deep .el-icon-arrow-down:before {
		content: "";
	}

	::v-deep .el-input__suffix {
		right: 0.425rem;
	}

	.block {

		// width: 100px;
		::v-deep .el-cascader .el-input .el-input__inner {
			border: none;
			background: #fff;
			padding: 0;
			-webkit-appearance: none;
		}
	}

	::v-deep .el-select .el-input {
		width: 6.225rem;
		background: #fff;
		color: #61687c;
		font-weight: 400;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}

	::v-deep .el-input-group__append {
		width: 108px;
		background-color: #126bf9;
		color: #fff;
		border: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		cursor: pointer;
	}

	.hot {
		font-size: 0.875rem;

		div {
			span {
				margin-right: 8px;
			}
		}
	}

	.Detailed-screening {
		width: 100%;
		margin-bottom: 1.25rem;
		display: flex;
		font-size: 0.9375rem;
		font-weight: 400;
		color: #414a60;
		line-height: 2.1875rem;

		.title {
			display: flex;
			flex-direction: column;
			float: left;
		}

		.type {
			display: flex;
			flex-direction: column;
			font-size: 0.875rem;
			color: #126bf9;
			padding-left: 1.25rem;
		}

		.message {
			.city {
				display: flex;
				font-size: 0.875rem;
				font-weight: 400;
				color: #61687c;

				.item {
					span {
						padding-left: 1.25rem;
					}
				}

				.all {
					padding-left: 1.25rem;
				}

				.our {
					text-align: left;
					margin: -1.575rem 28px;
					font-size: 0.875rem;
					position: absolute;
					z-index: 1;
					width: 600px;
					height: 400px;
					padding: 0 1.25rem;
					box-shadow: 1px 1px 1px #888;
					background: #fff;
					overflow-y: auto;

					.city-name {
						display: flex;
						flex-direction: column;

						.city-div {
							flex-wrap: wrap;
						}

						.label-span {
							width: 100px;
							-webkit-line-clamp: 1;
						}
					}
				}
			}
		}
	}
</style>
